import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import './MusicLibraryDropdown.css';

const MusicLibraryDropdown = ({
  selectedMusic,
  onMusicSelect,
  isSessionCreated,
  isMixingAudio,
  isMusicLoading,
  musicFiles,
  error
}) => {
  // Local state to maintain selected value
  const [localSelection, setLocalSelection] = useState(selectedMusic);

  // Update local selection when prop changes
  useEffect(() => {
    setLocalSelection(selectedMusic);
  }, [selectedMusic]);

  // Debug logging
  useEffect(() => {
    console.log('MusicLibraryDropdown state:', {
      selectedMusic,
      localSelection,
      isSessionCreated,
      isMixingAudio,
      musicFilesCount: musicFiles.length
    });
  }, [selectedMusic, localSelection, isSessionCreated, isMixingAudio, musicFiles]);

  const handleMusicSelection = (e) => {
    const selectedFileName = e.target.value;
    console.log('Music selection change:', {
      oldValue: selectedMusic,
      newValue: selectedFileName
    });

    if (!selectedFileName) return;

    if (!isSessionCreated) {
      toast.info('Please create a session first before selecting music');
      return;
    }

    if (isMixingAudio) {
      toast.info('Please wait for current mixing to complete');
      return;
    }

    setLocalSelection(selectedFileName);
    onMusicSelect(selectedFileName);
  };

  // Find the current music file for display
  const currentMusicFile = musicFiles.find(
    (music) => music.name === localSelection
  );

  return (
    <div className="music-library-dropdown">
      <select
        value={localSelection || ''}
        onChange={handleMusicSelection}
        disabled={isMusicLoading || isMixingAudio || !isSessionCreated}
        className="music-select"
      >
        <option value="" disabled>
          {isMusicLoading
            ? 'Loading music library...'
            : isMixingAudio
            ? 'Mixing audio...'
            : !isSessionCreated
            ? 'Create session first'
            : currentMusicFile?.displayName || 'Select background music'}
        </option>
        {(!isMusicLoading) &&
          musicFiles.map((music) => (
            <option key={music.name} value={music.name}>
              {music.displayName}
            </option>
          ))}
      </select>

      {isMixingAudio && (
        <div className="mixing-indicator">
          <span className="mixing-spinner"></span>
          <span>Mixing audio...</span>
        </div>
      )}

      {error && <div className="error-message">{error}</div>}
    </div>
  );
};

MusicLibraryDropdown.propTypes = {
  selectedMusic: PropTypes.string,
  onMusicSelect: PropTypes.func.isRequired,
  isSessionCreated: PropTypes.bool.isRequired,
  isMixingAudio: PropTypes.bool.isRequired,
  isMusicLoading: PropTypes.bool.isRequired,
  musicFiles: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      displayName: PropTypes.string.isRequired,
    })
  ).isRequired,
  error: PropTypes.string
};

export default MusicLibraryDropdown;