import React, { useState, useCallback, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { IoChevronUpSharp, IoChevronDownSharp } from 'react-icons/io5';
import { presetsMap } from './FilterPresets';
import './slider.css';

// Internal component for grouping controls
const ControlGroup = ({ label, tooltip, children, id, isSelect }) => (
  <div className="control-group">
    <div className="tooltip-container">
      <label htmlFor={id} className="control-label">{label}</label>
      {tooltip && (
        <span className="tooltip" title={tooltip} aria-label={`${label} tooltip`}>
          ⓘ
        </span>
      )}
    </div>
    {isSelect ? children : <div className="slider-wrapper">{children}</div>}
  </div>
);

ControlGroup.propTypes = {
  label: PropTypes.string.isRequired,
  tooltip: PropTypes.string,
  children: PropTypes.node.isRequired,
  id: PropTypes.string.isRequired,
  isSelect: PropTypes.bool,
};

// Internal component for managing filter controls
const FilterControls = ({
  filterName,
  filterData,
  onPresetSelect,
  onIndividualChange,
  disabled,
  calculateBackground,
  logControl,
  isSoloMode,
}) => {
  const handleParamChange = useCallback(
    (paramName, value) => {
      logControl(`${filterName} ${paramName} change`, {
        oldValue: filterData[paramName],
        newValue: value,
      });
      if (paramName === 'solo_sound_bowls') {
        const updatedFilter = {
          ...filterData,
          enabled: true,
          solo_sound_bowls: value,
          volume: value ? Math.min(filterData.volume || 0.85, 1.0) : filterData.volume,
        };
        onIndividualChange(null, updatedFilter);
      } else {
        onIndividualChange(paramName, value);
      }
    },
    [filterName, filterData, onIndividualChange, logControl]
  );

  const renderNumberControl = useCallback(
    (paramName, label, min, max, step = 0.01, unit = '') => (
      <ControlGroup
        label={label}
        tooltip={`Adjust ${label.toLowerCase()} for ${filterName}`}
        id={`${filterName}-${paramName}`}
      >
        <input
          type="range"
          min={min}
          max={max}
          step={step}
          value={filterData[paramName]}
          onChange={(e) => handleParamChange(paramName, Number(e.target.value))}
          className="filter-slider neomorphic-slider"
          disabled={disabled || !filterData.enabled}
          style={{ background: calculateBackground(filterData[paramName], min, max) }}
          aria-label={`${label} Control`}
        />
        <span className="slider-value">
          {unit === '%' ? `${(filterData[paramName] * 100).toFixed(0)}%` : `${filterData[paramName]}${unit}`}
        </span>
      </ControlGroup>
    ),
    [filterData, filterName, disabled, calculateBackground, handleParamChange]
  );

  const renderPatternControl = useCallback(() => (
    <ControlGroup
      label="Pattern"
      tooltip="Select the pattern of the panning effect"
      id={`${filterName}-pattern`}
      isSelect
    >
      <select
        value={filterData.pattern}
        onChange={(e) => handleParamChange('pattern', e.target.value)}
        className="filter-select neomorphic-select"
        disabled={disabled || !filterData.enabled}
        aria-label="Pattern Select"
      >
        <option value="sine">Sine</option>
        <option value="triangle">Triangle</option>
        <option value="square">Square</option>
        <option value="random">Random</option>
        <option value="hold_center">Hold Center</option>
      </select>
    </ControlGroup>
  ), [filterData.pattern, filterName, disabled, filterData.enabled, handleParamChange]);

  const renderFilterControls = useCallback(() => {
    switch (filterName) {
      case 'stereoPan':
        return !isSoloMode && (
          <>
            {renderNumberControl('value', 'Pan Strength', 0, 1, 0.01, '%')}
            {renderNumberControl('speed', 'Speed', 5, 60, 1, ' sec')}
            {renderNumberControl('hold_time', 'Hold Time', 0, 60, 1, ' sec')}
            {renderPatternControl()}
          </>
        );
        case 'soundBowls':
            return (
                <>
                    <ControlGroup
                        label="Bowl Type"
                        tooltip="Select singing bowl type and frequency"
                        id={`${filterName}-preset`}
                        isSelect
                    >
                        <select
                            value={filterData.preset || ''}
                            onChange={(e) => onPresetSelect(filterName, e.target.value)}
                            className="filter-select neomorphic-select"
                            disabled={disabled || !filterData.enabled}
                            aria-label={`Select preset for ${filterName}`}
                        >
                            <option value="" disabled>
                                Select a bowl
                            </option>
                            {presetsMap[filterName]?.map((preset) => (
                                <option key={preset.label} value={preset.label}>
                                    {preset.label}
                                </option>
                            ))}
                        </select>
                    </ControlGroup>
        
                    <ControlGroup label="Solo Sound Bowls" id={`${filterName}-solo`}>
                        <div className="solo-toggle-container">
                            <label className="flex items-center justify-between">
                                <input
                                    type="checkbox"
                                    checked={filterData.solo_sound_bowls}
                                    onChange={(e) => handleParamChange('solo_sound_bowls', e.target.checked)}
                                    className="neomorphic-checkbox"
                                    disabled={disabled}
                                />
                                <span className="solo-toggle-label ml-2">
                                    {filterData.solo_sound_bowls ? "Sound Bowls Only" : "Mix with other tracks"}
                                </span>
                            </label>
                        </div>
                    </ControlGroup>
        
                    <ControlGroup
                        label="Bowl Size"
                        tooltip="Affects resonance and harmonic character"
                        id={`${filterName}-size`}
                        isSelect
                    >
                        <select
                            value={filterData.bowl_size || 'medium'}
                            onChange={(e) => handleParamChange('bowl_size', e.target.value)}
                            className="filter-select neomorphic-select"
                            disabled={disabled || !filterData.enabled}
                            aria-label="Bowl Size"
                        >
                            <option value="small">Small Bowl</option>
                            <option value="medium">Medium Bowl</option>
                            <option value="large">Large Bowl</option>
                        </select>
                    </ControlGroup>
        
                    {renderNumberControl('base_frequency', 'Bowl Frequency', 150, 480, 1, ' Hz')}
                    {renderNumberControl('rub_speed', 'Rubbing Speed', 1.5, 3.0, 0.1)}
                    {renderNumberControl('rub_pressure', 'Contact Pressure', 0.6, 0.85, 0.01)}
                    {renderNumberControl('volume', 'Volume', 0.4, 0.9, 0.01)}
        
                    <ControlGroup
                        label="Space"
                        tooltip="Acoustic space simulation"
                        id={`${filterName}-ir`}
                        isSelect
                    >
                        <select
                            value={filterData.impulse_response_path || ''}
                            onChange={(e) => handleParamChange('impulse_response_path', e.target.value)}
                            className="filter-select neomorphic-select"
                            disabled={disabled || !filterData.enabled}
                            aria-label="Space Simulation"
                        >
                            <option value="">No Space</option>
                            {presetsMap.reverb.map((preset) => (
                                <option key={preset.label} value={preset.impulse_response_path}>
                                    {preset.label}
                                </option>
                            ))}
                        </select>
                    </ControlGroup>
        
                    {renderNumberControl('wet', 'Space Amount', 0, 2.0, 0.1)}
                    {renderNumberControl('dry', 'Direct Signal', 0.5, 1.0, 0.1)}
        
                    <div className="text-xs text-gray-500 mt-2">
                        {presetsMap.soundBowls.find(p => p.label === filterData.preset)?.description ||
                            "Experience dual singing bowls with natural resonance and random strikes"}
                    </div>
                </>
            );
            
      case 'oceanWaves':
        return !isSoloMode && (
          <>
            {renderNumberControl('noise_amplitude', 'Wave Intensity', 0, 1, 0.01, '%')}
            {renderNumberControl('wave_frequency', 'Wave Speed', 0.05, 0.5, 0.01, ' Hz')}
            {renderNumberControl('volume', 'Volume', 0, 1, 0.01, '%')}
          </>
        );
      case 'reverb':
        return !isSoloMode && (
          <>
            <ControlGroup
              label="Select Preset"
              tooltip="Choose a reverb preset"
              id={`${filterName}-preset`}
              isSelect
            >
              <select
                value={filterData.preset || ''}
                onChange={(e) => onPresetSelect(filterName, e.target.value)}
                className="filter-select neomorphic-select"
                disabled={disabled || !filterData.enabled}
                aria-label={`Select preset for ${filterName}`}
              >
                <option value="" disabled>
                  Select a preset
                </option>
                {presetsMap.reverb?.map((preset) => (
                  <option key={preset.label} value={preset.label}>
                    {preset.label}
                  </option>
                ))}
              </select>
            </ControlGroup>
            {renderNumberControl('wet', 'Reverb Amount', 0, 2.0, 0.1, '%')}
            {renderNumberControl('dry', 'Original Signal', 0, 1.0, 0.1, '%')}
            <div className="text-xs text-gray-500 mt-2">
              {presetsMap.reverb.find(p => p.label === filterData.preset)?.description}
            </div>
          </>
        );
      default:
        return null;
    }
  }, [filterName, filterData, disabled, onPresetSelect, handleParamChange, renderNumberControl, renderPatternControl, isSoloMode]);

  return (
    <div className="filter-controls-content">
      {renderFilterControls()}
    </div>
  );
};

FilterControls.propTypes = {
  filterName: PropTypes.string.isRequired,
  filterData: PropTypes.object.isRequired,
  onPresetSelect: PropTypes.func.isRequired,
  onIndividualChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  calculateBackground: PropTypes.func.isRequired,
  logControl: PropTypes.func.isRequired,
  isSoloMode: PropTypes.bool.isRequired,
};

// Main volume slider component
const MainVolumeSlider = ({ label, name, value, onChange, disabled, calculateBackground, isSoloMode }) => (
  <div className="slider-container">
    <label htmlFor={`${name}-slider`} className="control-label">
      {label}
      {isSoloMode && name !== 'overall_volume' && (
        <span className="ml-2 text-sm text-gray-400">(Muted in solo mode)</span>
      )}
    </label>
    <div className="slider-wrapper">
      <input
        id={`${name}-slider`}
        type="range"
        min="0"
        max="100"
        step="1"
        value={Math.round(value * 100)}
        onChange={(e) => onChange(name, parseFloat(e.target.value) / 100)}
        disabled={disabled || (isSoloMode && name !== 'overall_volume')}
        className={`neomorphic-slider ${isSoloMode && name !== 'overall_volume' ? 'opacity-50' : ''}`}
        style={{ background: calculateBackground(Math.round(value * 100), 0, 100) }}
        aria-label={`${label} Control`}
      />
      <span className="slider-value">{Math.round(value * 100)}%</span>
    </div>
  </div>
);

MainVolumeSlider.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  calculateBackground: PropTypes.func.isRequired,
  isSoloMode: PropTypes.bool,
};

// Main VolumeControls component
const VolumeControls = ({
  baseUrl,
  volume,
  setVolume,
  filters,
  onApplyFilters,
  selectedMusic,
  ttsKey,
  isMixingAudio,
  disabled,
  isMusicLoading,
  hasChanges,
  setHasChanges,
  pendingVolume,
  pendingFilters,
  setPendingChanges,
  onPresetSelect,
  onFilterChange,
}) => {
  const [expandedFilter, setExpandedFilter] = useState(null);
  const [previousVolumes, setPreviousVolumes] = useState(null);
  const [isSoloMode, setIsSoloMode] = useState(false);

  const logControl = useCallback((action, data = {}) => {
    const timestamp = new Date().toISOString();
    console.log(`[VolumeControls ${timestamp}] ${action}:`, data);
  }, []);

  const calculateBackground = useCallback((value, min = 0, max = 1) => {
    const percentage = ((value - min) / (max - min)) * 100;
    return `linear-gradient(to right, #a020f0 0%, #a020f0 ${percentage}%, #444 ${percentage}%, #444 100%)`;
  }, []);

  const currentFilters = useMemo(() => ({
    ...filters,
    ...(pendingFilters || {})
  }), [filters, pendingFilters]);

  const handleVolumeChange = useCallback((type, newValue) => {
    console.log(`Volume change detected for ${type}:`, newValue);
    if (typeof newValue !== 'number' || isNaN(newValue)) {
      console.warn(`Invalid volume value received for ${type}:`, newValue);
      return;
    }
    const value = Math.min(Math.max(Number(newValue), 0), 1);
    setVolume(prev => ({
      ...prev,
      [type]: value,
    }));
    setHasChanges(true);
  }, [setVolume, setHasChanges]);

  const handleFilterChange = useCallback((filterName, updatedFilter) => {
    logControl('Filter change', { filterName, updatedFilter });

    // Handle sound bowls solo mode and volume management
    if (filterName === 'soundBowls' && 'solo_sound_bowls' in updatedFilter) {
        const enteringSoloMode = updatedFilter.solo_sound_bowls;
        
        if (enteringSoloMode && !previousVolumes) {
            // Store current volumes before entering solo mode
            setPreviousVolumes({
                tts: volume.tts_volume,
                bg: volume.bg_volume,
            });
            // Mute other tracks
            setVolume(prev => ({
                ...prev,
                tts_volume: 0,
                bg_volume: 0,
            }));
        } else if (!enteringSoloMode && previousVolumes) {
            // Restore previous volumes when exiting solo mode
            setVolume(prev => ({
                ...prev,
                tts_volume: previousVolumes.tts,
                bg_volume: previousVolumes.bg,
            }));
            setPreviousVolumes(null);
        }
        setIsSoloMode(enteringSoloMode);
    }

    // Process numeric values for sound bowls
    let processedFilter = { ...updatedFilter };
    if (filterName === 'soundBowls') {
        processedFilter = {
            ...processedFilter,
            enabled: true,
            base_frequency: Number(updatedFilter.base_frequency) || 432.0,
            rub_speed: Number(updatedFilter.rub_speed) || 2.5,    // Updated default
            rub_pressure: Number(updatedFilter.rub_pressure) || 0.75,
            resonance: Number(updatedFilter.resonance) || 0.9991, // Updated for smoother resonance
            volume: Number(updatedFilter.volume) || 0.82,
            bowl_size: updatedFilter.bowl_size || "medium",
            wet: Number(updatedFilter.wet) || 1.2,
            dry: Number(updatedFilter.dry) || 0.85,
            impulse_response_path: updatedFilter.impulse_response_path || ""
        };
    }

    // Update the pending changes
    setPendingChanges(prev => ({
        ...prev,
        filters: {
            ...(prev.filters || {}),
            [filterName]: {
                ...(filters[filterName] || {}),
                ...processedFilter,
                enabled: processedFilter.enabled ?? filters[filterName]?.enabled ?? true,
            },
        },
    }));
    
    setHasChanges(true);
}, [logControl, setPendingChanges, setHasChanges, filters, previousVolumes, setVolume, volume, setIsSoloMode]);

const handlePresetSelectionInternal = useCallback((filterName, presetLabel) => {
    const preset = presetsMap[filterName]?.find(p => p.label === presetLabel);
    if (!preset) return;

    if (filterName === 'soundBowls') {
        handleFilterChange(filterName, {
            enabled: true,
            preset: presetLabel,
            base_frequency: Number(preset.base_frequency),
            rub_speed: Number(preset.rub_speed),
            rub_pressure: Number(preset.rub_pressure),
            resonance: Number(preset.resonance),
            excitation_rate: Number(preset.excitation_rate),
            excitation_depth: Number(preset.excitation_depth),
            modulation: Number(preset.modulation),
            harmonic_mix: Number(preset.harmonic_mix),
            volume: Number(preset.volume),
            bowl_size: preset.bowl_size || "medium",
            wet: Number(preset.wet),
            dry: Number(preset.dry),
            impulse_response_path: preset.impulse_response_path || "",
            solo_sound_bowls: preset.solo_sound_bowls || false
        });
    } else {
        // Handle other filter types normally
        handleFilterChange(filterName, {
            ...preset,
            preset: presetLabel,
            enabled: true
        });
    }

    logControl(`Selected preset ${presetLabel} for ${filterName}`, preset);
}, [handleFilterChange, logControl]);


  const handleFilterHeaderClick = useCallback((filterName, event) => {
    const isCheckboxClick = event.target.closest('.neomorphic-checkbox');
    const isLabelClick = event.target.closest('.filter-toggle');
    if (isCheckboxClick || isLabelClick) return;
    setExpandedFilter(prev => (prev === filterName ? null : filterName));
  }, []);

  useEffect(() => {
    const soundBowlsEnabled = currentFilters.soundBowls?.enabled;
    const soloEnabled = soundBowlsEnabled && currentFilters.soundBowls?.solo_sound_bowls;
    if (soloEnabled !== isSoloMode) {
      setIsSoloMode(soloEnabled);
      if (soloEnabled && !previousVolumes) {
        setPreviousVolumes({
          tts: volume.tts_volume,
          bg: volume.bg_volume,
        });
        setVolume(prev => ({
          ...prev,
          tts_volume: 0,
          bg_volume: 0,
        }));
      } else if (!soloEnabled && previousVolumes) {
        setVolume(prev => ({
          ...prev,
          tts_volume: previousVolumes.tts,
          bg_volume: previousVolumes.bg,
        }));
        setPreviousVolumes(null);
      }
    }
  }, [currentFilters.soundBowls?.enabled, currentFilters.soundBowls?.solo_sound_bowls, isSoloMode, previousVolumes, setVolume, volume]);

  return (
    <div className={`volume-controls ${disabled ? 'disabled' : ''}`}>
      <div className={`volume-section ${isSoloMode ? 'solo-mode' : ''}`}>
        {isSoloMode && (
          <div className="solo-mode-banner">
            <div className="flex items-center justify-center bg-purple-800 text-white p-2 rounded-t-md">
              <span className="text-sm font-medium">Sound Bowls Solo Mode Active</span>
            </div>
          </div>
        )}
        <div className="volume-sliders">
          <MainVolumeSlider
            label="Master Volume"
            name="overall_volume"
            value={volume.overall_volume}
            onChange={handleVolumeChange}
            disabled={disabled}
            calculateBackground={calculateBackground}
            isSoloMode={isSoloMode}
          />
          <MainVolumeSlider
            label="Voice Volume"
            name="tts_volume"
            value={previousVolumes?.tts ?? volume.tts_volume}
            onChange={handleVolumeChange}
            disabled={disabled}
            calculateBackground={calculateBackground}
            isSoloMode={isSoloMode}
          />
          <MainVolumeSlider
            label="Music Volume"
            name="bg_volume"
            value={previousVolumes?.bg ?? volume.bg_volume}
            onChange={handleVolumeChange}
            disabled={disabled}
            calculateBackground={calculateBackground}
            isSoloMode={isSoloMode}
          />
        </div>
      </div>
      <div className="filters-section">
        <h3>Audio Effects</h3>
        <div className="filters-grid">
          {Object.entries(currentFilters).map(([filterName, filterData]) => (
            <div key={filterName} className={`filter-wrapper ${expandedFilter === filterName ? 'expanded' : ''}`}>
              <div className="filter-header" onClick={(e) => handleFilterHeaderClick(filterName, e)} role="button" tabIndex={0}>
                <label className="filter-toggle">
                  <input
                    type="checkbox"
                    checked={filterData.enabled}
                    onChange={() =>
                      handleFilterChange(filterName, {
                        ...filterData,
                        enabled: !filterData.enabled,
                        ...(filterName === 'soundBowls' && !filterData.enabled && { solo_sound_bowls: false }),
                      })
                    }
                    disabled={disabled || (isSoloMode && filterName !== 'soundBowls')}
                    className="neomorphic-checkbox"
                    aria-label={`Toggle ${filterName} filter`}
                  />
                  <span>{filterName.replace(/([A-Z])/g, ' $1').trim()}</span>
                </label>
                <div className="chevron-container">
                  {expandedFilter === filterName ? <IoChevronUpSharp /> : <IoChevronDownSharp />}
                </div>
              </div>
              {expandedFilter === filterName && (
                <div className={`filter-controls ${filterData.enabled ? 'enabled' : 'disabled'}`}>
                  <FilterControls
                    filterName={filterName}
                    filterData={filterData}
                    onPresetSelect={handlePresetSelectionInternal}
                    onIndividualChange={(paramName, value) => {
                      if (paramName === null) {
                        handleFilterChange(filterName, value);
                      } else {
                        handleFilterChange(filterName, {
                          ...filterData,
                          [paramName]: value,
                        });
                      }
                    }}
                    disabled={disabled || (isSoloMode && filterName !== 'soundBowls')}
                    calculateBackground={calculateBackground}
                    logControl={logControl}
                    isSoloMode={isSoloMode}
                  />
                </div>
              )}
            </div>
          ))}
        </div>
        {hasChanges && (
          <div className="filters-apply-button-container">
            <button
              className="filters-apply-button"
              onClick={() => {
                const payloadVolume = {
                  overall_volume: volume.overall_volume,
                  tts_volume: isSoloMode ? 0 : volume.tts_volume,
                  bg_volume: isSoloMode ? 0 : volume.bg_volume,
                };
                onApplyFilters({
                  volume: payloadVolume,
                  filters: Object.entries(currentFilters)
                    .filter(([_, data]) => data.enabled)
                    .reduce((acc, [key, data]) => ({
                      ...acc,
                      [key]:
                        key === 'soundBowls'
                          ? {
                              ...data,
                              base_frequency_range: [
                                Number(data.base_frequency) * 0.95,
                                Number(data.base_frequency) * 1.05,
                              ],
                              volume: isSoloMode ? Math.min(Number(data.volume), 1.0) : Number(data.volume),
                              solo_sound_bowls: Boolean(data.solo_sound_bowls),
                            }
                          : {
                              ...data,
                              enabled: isSoloMode ? false : data.enabled,
                            },
                    }), {}),
                });
              }}
              disabled={disabled || isMixingAudio}
            >
              {isMixingAudio ? 'Applying Changes...' : 'Apply Changes'}
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

VolumeControls.propTypes = {
  baseUrl: PropTypes.string.isRequired,
  volume: PropTypes.shape({
    overall_volume: PropTypes.number.isRequired,
    tts_volume: PropTypes.number.isRequired,
    bg_volume: PropTypes.number.isRequired,
  }).isRequired,
  setVolume: PropTypes.func.isRequired,
  filters: PropTypes.object.isRequired,
  onApplyFilters: PropTypes.func.isRequired,
  selectedMusic: PropTypes.string.isRequired,
  ttsKey: PropTypes.string.isRequired,
  isMixingAudio: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  isMusicLoading: PropTypes.bool.isRequired,
  hasChanges: PropTypes.bool.isRequired,
  setHasChanges: PropTypes.func.isRequired,
  pendingVolume: PropTypes.object,
  pendingFilters: PropTypes.object,
  setPendingChanges: PropTypes.func.isRequired,
  onPresetSelect: PropTypes.func.isRequired,
  onFilterChange: PropTypes.func.isRequired,
};

VolumeControls.defaultProps = {
  pendingVolume: null,
  pendingFilters: null,
  disabled: false,
  isMusicLoading: false,
  isMixingAudio: false,
  hasChanges: false,
};

export default VolumeControls;
