// src/pages/MeditationApp/MeditationApp.js

import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import CreateButton from './components/CreateButton';
import VoiceSelector from './components/VoiceSelector';
import VolumeControls from './components/VolumeControls';
import PushToSpeakButton from './components/PushToSpeakButton';
import Preloader from './components/Preloader';
import DecisionTree from './components/DecisionTree';
import AudioPlayer from './components/AudioPlayer';
import ExportButton from './components/ExportButton';
import { ConversationProvider } from './components/ConversationContext';
import MergedTextEditor from './components/MergedTextEditor';
import debounce from 'lodash/debounce';
import Joi from 'joi';
import { ToastContainer, toast, Slide } from 'react-toastify';
import logo from '../../assets/logot.png';
import './App.css';
import './components/Button.css';
import 'react-toastify/dist/ReactToastify.css';
import Header from './components/Header';
import LoadingIndicator from './components/LoadingIndicator';
import { presetsMap } from './components/FilterPresets';

// LoadingController remains unchanged
const LoadingController = {
  startTime: null,
  minimumDuration: 2000,
  start() {
    this.startTime = Date.now();
  },
  shouldKeepLoading() {
    if (!this.startTime) return false;
    return Date.now() - this.startTime < this.minimumDuration;
  },
  getRemainingTime() {
    if (!this.startTime) return 0;
    return Math.max(0, this.minimumDuration - (Date.now() - this.startTime));
  },
  reset() {
    this.startTime = null;
  }
};

const MeditationApp = () => {
  // Base URL from .env
  const baseUrl = useMemo(() => process.env.REACT_APP_API_BASE_URL || 'https://api.pause.site', []);

  // Abort controller to cancel fetch requests
  const abortControllerRef = useRef(null);
  const lastBaseAudioUrlRef = useRef('');
  const lastMixedAudioUrlRef = useRef('');

  // Volume states
  const [volume, setVolume] = useState({
    overall_volume: 1,
    tts_volume: 1,
    bg_volume: 0.2
  });

  // Music states
  const [musicFiles, setMusicFiles] = useState([]);
  const [selectedMusic, setSelectedMusic] = useState('');
  const [isMusicLoading, setIsMusicLoading] = useState(false);
  const [isMixingAudio, setIsMixingAudio] = useState(false);
  const [ttsKey, setTTSKey] = useState('');

  const [pendingChanges, setPendingChanges] = useState({
    volume: null,
    filters: {}  // always use an object for filters
  });

  const checkSoundBowlsSoloMode = (filters) => {
    return filters.soundBowls?.enabled && filters.soundBowls?.solo_sound_bowls;
  };

  // Filters states
  const [filters, setFilters] = useState({
    stereoPan: {
      enabled: false,
      value: 0.5,
      speed: 30,
      hold_time: 10,
      pattern: 'sine',
      preset: ''
    },
    soundBowls: {
      enabled: false,
      base_frequency: 432.0,     // Default to A=432Hz
      rub_speed: 3.0,           // Physical mallet movement
      rub_pressure: 0.7,        // Pressure against rim
      resonance: 0.9992,        // High Q resonance
      excitation_rate: 8.0,     // Energy input rate
      excitation_depth: 0.12,   // Input intensity
      modulation: 0.07,         // Hand variation
      harmonic_mix: 0.92,       // Overtone content
      volume: 0.85,
      bowl_size: "medium",      // Physical size
      wet: 1.4,                 // Reverb amount
      dry: 0.8,                 // Direct signal
      preset: "",
      solo_sound_bowls: false,
      impulse_response_path: ""
    },

    oceanWaves: {
      enabled: false,
      wave_frequency: 0.1,
      noise_amplitude: 0.2,
      volume: 0.5,
      preset: ''
    },
    reverb: {
      enabled: false,
      impulse_response_path: "",
      wet: 0.5,
      dry: 1.0,
      preset: ""
    }
  });

  const memoizedVolume = useMemo(() => ({
    overall_volume: volume.overall_volume,
    tts_volume: volume.tts_volume,
    bg_volume: volume.bg_volume
  }), [volume.overall_volume, volume.tts_volume, volume.bg_volume]);

  const memoizedMusicLibrary = useMemo(() => musicFiles, [musicFiles]);

  const mixingPayloadSchema = useMemo(() => Joi.object({
    bg_volume: Joi.number().min(0).max(1).required(),
    tts_volume: Joi.number().min(0).max(1).required(),
    overall_volume: Joi.number().min(0).max(1).required(),
    bg_audio: Joi.alternatives().conditional('bg_volume', {
      is: 0,
      then: Joi.string().optional(),
      otherwise: Joi.string().required()
    }),
    tts_audio: Joi.string().required(),
    filters: Joi.object().required()
  }), []);

  // Audio states
  const [isMixedAudioLoaded, setIsMixedAudioLoaded] = useState(false);
  const [mixedAudioUrl, setMixedAudioUrl] = useState('');
  const [isPlaying, setIsPlaying] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);
  const [isAudioLoading, setIsAudioLoading] = useState(false);

  // Processing states
  const [isLoading, setIsLoading] = useState(false);
  const [isScriptLoading, setIsScriptLoading] = useState(false);
  const [isTranscribing, setIsTranscribing] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const [isRecordingAnimation, setIsRecordingAnimation] = useState(false);
  const [isScriptGenerated, setIsScriptGenerated] = useState(false);
  const [isTranscribed, setIsTranscribed] = useState(false);
  const [isPersonalizationMode, setIsPersonalizationMode] = useState(false);
  const [isSessionCreated, setIsSessionCreated] = useState(false);
  const [isCreatingSession, setIsCreatingSession] = useState(false);
  const [isTTSLoaded, setIsTTSLoaded] = useState(false);
  const [changeType, setChangeType] = useState(null);

  // Refs for isLoading and isMixingAudio to prevent infinite loops
  const isLoadingRef = useRef(isLoading);
  const isMixingAudioRef = useRef(isMixingAudio);

  const [previousVolumes, setPreviousVolumes] = useState(null);
  const [isSoloMode, setIsSoloMode] = useState(false);

  const validateAudioUrl = useCallback((url) => {
    try {
      console.log('[URL-VALIDATE] Starting validation for:', url);
      const parsed = new URL(url);
      const isS3Url = parsed.hostname.includes('s3') && parsed.hostname.includes('amazonaws.com');
      const hasV2Signature = parsed.searchParams.has('AWSAccessKeyId') &&
                             parsed.searchParams.has('Signature') &&
                             parsed.searchParams.has('Expires');
      const hasV4Signature = parsed.searchParams.has('X-Amz-Algorithm') &&
                             parsed.searchParams.has('X-Amz-Credential') &&
                             parsed.searchParams.has('X-Amz-Date') &&
                             parsed.searchParams.has('X-Amz-Expires');
      const hasRequiredHeaders = parsed.searchParams.has('response-content-type') &&
                                 parsed.searchParams.has('response-content-disposition');
      const validationResult = {
        isValid: isS3Url && (hasV2Signature || hasV4Signature) && hasRequiredHeaders,
        baseUrl: `${parsed.origin}${parsed.pathname}`.replace(/\/+$/, '').toLowerCase(),
        validationDetails: {
          isS3Url,
          hasV2Signature,
          hasV4Signature,
          hasRequiredHeaders,
          hostname: parsed.hostname,
          params: Object.fromEntries(parsed.searchParams)
        }
      };
      console.log('[URL-VALIDATE] Validation details:', {
        url: parsed.toString(),
        result: validationResult,
        headers: {
          contentType: parsed.searchParams.get('response-content-type'),
          contentDisposition: parsed.searchParams.get('response-content-disposition')
        }
      });
      return validationResult;
    } catch (e) {
      console.error('[URL-VALIDATE] Validation error:', e);
      return { 
        isValid: false, 
        baseUrl: null,
        error: e.message,
        validationDetails: {
          error: e.message,
          stack: e.stack
        }
      };
    }
  }, []);

  // UI states
  const [sidebarState, setSidebarState] = useState({
    isOpen: window.innerWidth > 768,
    hasBackdrop: false
  });
  const [editorMode, setEditorMode] = useState('prompt');
  const [isTextEditorGlowing, setIsTextEditorGlowing] = useState(false);
  const [isEnhancedEditorGlowing, setIsEnhancedEditorGlowing] = useState(false);
  const [showDecisionTree, setShowDecisionTree] = useState(false);
  const [error, setError] = useState({ message: null, type: null });

  // Script & Content states
  const [customPrompt, setCustomPrompt] = useState('');
  const [meditationScript, setMeditationScript] = useState('');
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [personalizedNote, setPersonalizedNote] = useState('');
  const [selectedVoice, setSelectedVoice] = useState('onyx');

  const filtersBackend = useMemo(() => {
    const currentFilters = {
      ...filters,
      ...(pendingChanges.filters || {})
    };
    
    const isSoloModeActive = checkSoundBowlsSoloMode(currentFilters);
    const backendFilters = {};
    
    Object.entries(currentFilters).forEach(([key, filter]) => {
      if (filter.enabled) {
        if (isSoloModeActive && key !== 'soundBowls') {
          return;
        }
        switch (key) {
          case 'stereoPan':
            backendFilters[key] = {
              enabled: !isSoloModeActive,
              value: Number(filter.value),
              speed: Number(filter.speed),
              pattern: filter.pattern
            };
            break;
            case 'soundBowls':
              backendFilters["soundBowls"] = {
                  enabled: true,
                  base_frequency: Number(filter.base_frequency),
                  rub_speed: Number(filter.rub_speed),
                  rub_pressure: Number(filter.rub_pressure),
                  resonance: Number(filter.resonance),
                  bowl_size: filter.bowl_size || "medium",
                  volume: Number(filter.volume),
                  wet: Number(filter.wet),
                  dry: Number(filter.dry),
                  solo_sound_bowls: Boolean(filter.solo_sound_bowls),
                  impulse_response_path: filter.impulse_response_path || ""
              };
              break;
              
          case 'oceanWaves':
            backendFilters[key] = {
              enabled: !isSoloModeActive,
              wave_frequency: Number(filter.wave_frequency),
              noise_amplitude: Number(filter.noise_amplitude),
              volume: Number(filter.volume)
            };
            break;
          case 'reverb':
            backendFilters[key] = {
              enabled: !isSoloModeActive,
              impulse_response_path: filter.impulse_response_path || "",
              wet: Number(filter.wet),
              dry: Number(filter.dry)
            };
            break;
          default:
            console.warn(`Unknown filter: ${key}`);
        }
      }
    });
  
    return backendFilters;
  }, [filters, pendingChanges.filters]);
  
  // Helper function to clean generated script text
  const cleanText = useCallback((text) => {
    return text
      .replace(/\s*\[PROSODY_START\]\s*|\s*\[PROSODY_END\]\s*|\s*\[PAUSE( LONG)?\]\s*/g, ' ')
      .replace(/\s+/g, ' ')
      .trim();
  }, []);

  // Sets the new audio URL for the AudioPlayer, avoiding repeated loads
  const setNewMixedAudioUrl = useCallback((newUrl) => {
    try {
      const url = new URL(newUrl);
      const baseUrlWithoutParams = `${url.origin}${url.pathname}`.replace(/\/+$/, '').toLowerCase();
      if (baseUrlWithoutParams !== lastBaseAudioUrlRef.current) {
        lastBaseAudioUrlRef.current = baseUrlWithoutParams;
        setMixedAudioUrl(newUrl);
      }
    } catch (error) {
      setError({
        message: 'Received an invalid audio URL from the server.',
        type: 'audio'
      });
      toast.error('Received an invalid audio URL from the server.');
    }
  }, []);

  // =============== DEFINE logControl =============== //
  const logControl = useCallback((action, data = {}) => {
    const timestamp = new Date().toISOString();
    console.log(`[MeditationApp ${timestamp}] ${action}:`, data);
  }, []);
  // =============== END logControl =============== //

  async function streamGenerateScript(url, requestOptions, updateTextCallback) {
    const response = await fetch(url, requestOptions);
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const reader = response.body.getReader();
    const decoder = new TextDecoder("utf-8");
    let accumulatedText = "";
  
    while (true) {
      const { value, done } = await reader.read();
      if (done) break;
      const chunk = decoder.decode(value, { stream: true }).trim();
      const lines = chunk.split('\n');
      for (const line of lines) {
        if (line.startsWith("data: ")) {
          const jsonStr = line.slice("data: ".length);
          if (jsonStr === "[DONE]") continue;
          try {
            const data = JSON.parse(jsonStr);
            const content = data?.choices?.[0]?.delta?.content || "";
            if (content) {
              accumulatedText += content;
              updateTextCallback(accumulatedText);
            }
          } catch (e) {
            console.error("Error parsing JSON:", e);
          }
        }
      }
    }
    return accumulatedText;
  }

  // =============== SCRIPT GENERATION HANDLER =============== //
  const handleGenerateScript = useCallback(async (personalizedPrompt = '') => {
    if (isScriptLoading) return;
    try {
      setIsScriptLoading(true);
      setMeditationScript('');
      setIsSessionCreated(false);
      setIsTTSLoaded(false);
      setIsCreatingSession(false);
      LoadingController.start();
  
      const prompt = personalizedPrompt || customPrompt.trim();
      if (!prompt) throw new Error('Cannot generate a script with an empty prompt.');
  
      const response = await fetch(`${baseUrl}/generate-ssml`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          prompt,
          selected_options: selectedOptions.map(option => option.label),
          selected_voice: selectedVoice,
          transcription: customPrompt
        })
      });
  
      if (!response.ok) throw new Error('Failed to generate script');
  
      const data = await response.json();
      
      if (data.status === 'success') {
        setMeditationScript(data.data.display_script);
        setEditorMode('script');
        setIsScriptGenerated(true);
  
        const remainingTime = LoadingController.getRemainingTime();
        await new Promise(resolve => setTimeout(resolve, remainingTime));
        toast.success('Script generated successfully!');
      } else {
        throw new Error(data.message || 'Failed to generate script');
      }
  
    } catch (error) {
      console.error('Script generation error:', error);
      setError({ message: error.message, type: 'script' });
      toast.error(`Script generation failed: ${error.message}`);
      setIsScriptGenerated(false);
    } finally {
      setIsScriptLoading(false);
      setIsPersonalizationMode(false);
      setShowDecisionTree(false);
      LoadingController.reset();
    }
  }, [
    baseUrl,
    customPrompt,
    selectedOptions,
    selectedVoice,
    isScriptLoading
  ]);
  
  // =============== FILTERS & VOLUME HANDLERS =============== //
  const handleApplyFilters = useCallback(async (changedSettings) => {
    try {
      setIsMixingAudio(true);
      LoadingController.start();
      if (!ttsKey) {
        throw new Error('No audio session found. Please create a session first.');
      }
      if (!isSoloMode && !selectedMusic) {
        throw new Error('Please select background music first.');
      }
      const mixPayload = {
        bg_volume: isSoloMode ? 0 : Math.min(Math.max(Number(changedSettings.volume.bg_volume), 0), 1),
        tts_volume: isSoloMode ? 0 : Math.min(Math.max(Number(changedSettings.volume.tts_volume), 0), 1),
        overall_volume: Math.min(Math.max(Number(changedSettings.volume.overall_volume), 0), 1),
        bg_audio: encodeURIComponent(selectedMusic.startsWith('Background_Music/') ? 
          selectedMusic : `Background_Music/${selectedMusic}`),
        tts_audio: encodeURIComponent(ttsKey.startsWith('TTS/') ? 
          ttsKey : `TTS/${ttsKey}`),
        filters: changedSettings.filters
      };
  
      console.log('Sending mix payload:', mixPayload);
  
      const response = await fetch(`${baseUrl}/get-mixed-audio`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Origin': 'https://pause.site'
        },
        credentials: 'include',
        body: JSON.stringify(mixPayload)
      });
  
      if (!response.ok) {
        throw new Error(`Server error: ${response.status}`);
      }
  
      const responseData = await response.json();
      
      if (responseData.status !== 'success' || !responseData.data?.file_path) {
        throw new Error('Invalid response format from server');
      }
  
      const validationResult = validateAudioUrl(responseData.data.file_path);
      if (!validationResult.isValid) {
        throw new Error('Invalid audio URL received');
      }
  
      lastMixedAudioUrlRef.current = responseData.data.file_path;
      setNewMixedAudioUrl(responseData.data.file_path);
      setHasChanges(false);
      setIsMixedAudioLoaded(true);
      toast.success('Changes applied successfully');
  
    } catch (error) {
      console.error('Audio mixing error:', error);
      setError({ message: error.message, type: 'mixing' });
      setIsMixedAudioLoaded(false);
      if (lastMixedAudioUrlRef.current) {
        setMixedAudioUrl(lastMixedAudioUrlRef.current);
        toast.info('Restored previous mix');
      }
    } finally {
      setIsMixingAudio(false);
      LoadingController.reset();
    }
  }, [
    baseUrl,
    ttsKey,
    selectedMusic,
    isSoloMode,
    validateAudioUrl,
    setNewMixedAudioUrl,
    setHasChanges,
    setIsMixedAudioLoaded
  ]);
  
  // =============== AUDIO MIXING HANDLER =============== //
  const handleAudioMixing = useCallback(async () => {
    if (!selectedMusic || !ttsKey || isLoadingRef.current || isMixingAudioRef.current) {
      console.log("Audio mixing aborted: required data missing or another process is active");
      return;
    }
    try {
      setIsMixingAudio(true);
      isLoadingRef.current = true;
      isMixingAudioRef.current = true;
      setIsMixedAudioLoaded(false);
  
      const isSoloEnabled = checkSoundBowlsSoloMode(filters);
      const formattedFilters = {};
      Object.entries(filters).forEach(([key, filter]) => {
        if (filter.enabled) {
          if (isSoloEnabled && key !== 'soundBowls') {
            return;
          }
          switch (key) {
            case 'reverb':
              formattedFilters[key] = {
                enabled: !isSoloEnabled,
                wet: Number(filter.wet),
                dry: Number(filter.dry),
                impulse_response_path: filter.impulse_response_path || ""
              };
              break;
            case 'stereoPan':
              formattedFilters[key] = {
                enabled: !isSoloEnabled,
                value: Number(filter.value),
                speed: Number(filter.speed),
                pattern: filter.pattern
              };
              break;
              case 'soundBowls':
                formattedFilters[key] = {
                    enabled: true,
                    base_frequency: Number(filter.base_frequency),
                    rub_speed: Number(filter.rub_speed),
                    rub_pressure: Number(filter.rub_pressure),
                    resonance: Number(filter.resonance),
                    bowl_size: filter.bowl_size || "medium",
                    volume: Number(filter.volume),
                    wet: Number(filter.wet),
                    dry: Number(filter.dry),
                    solo_sound_bowls: Boolean(filter.solo_sound_bowls),
                    impulse_response_path: filter.impulse_response_path || ""
                };
            break;
            case 'oceanWaves':
              formattedFilters[key] = {
                enabled: !isSoloEnabled,
                wave_frequency: Number(filter.wave_frequency),
                noise_amplitude: Number(filter.noise_amplitude),
                volume: Number(filter.volume)
              };
              break;
          }
        }
      });
  
      const mixPayload = {
        bg_volume: isSoloEnabled ? 0 : Math.min(Math.max(Number(volume.bg_volume), 0), 1),
        tts_volume: isSoloEnabled ? 0 : Math.min(Math.max(Number(volume.tts_volume), 0), 1),
        overall_volume: Math.min(Math.max(Number(volume.overall_volume), 0), 1),
        bg_audio: encodeURIComponent(selectedMusic.startsWith('Background_Music/') ? 
          selectedMusic : `Background_Music/${selectedMusic}`),
        tts_audio: encodeURIComponent(ttsKey.startsWith('TTS/') ? 
          ttsKey : `TTS/${ttsKey}`),
        filters: formattedFilters
      };
  
      const response = await fetch(`${baseUrl}/get-mixed-audio`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Origin': 'https://pause.site'
        },
        credentials: 'include',
        body: JSON.stringify(mixPayload)
      });
  
      if (!response.ok && lastMixedAudioUrlRef.current) {
        console.log('Mixing failed, attempting to use last known good URL');
        setMixedAudioUrl(lastMixedAudioUrlRef.current);
        toast.warning('Using previous mix while retrying...');
        return;
      }
  
      if (!response.ok) {
        throw new Error('Server error during audio mixing');
      }
  
      const responseData = await response.json();
      if (responseData.status === 'success' && responseData.data?.file_path) {
        const validationResult = validateAudioUrl(responseData.data.file_path);
        if (!validationResult.isValid) {
          throw new Error('Invalid mixed audio URL received');
        }
        
        lastMixedAudioUrlRef.current = responseData.data.file_path;
        setNewMixedAudioUrl(responseData.data.file_path);
        setHasChanges(false);
        setIsMixedAudioLoaded(true);
        toast.success('Audio mixed successfully');
      } else {
        throw new Error(responseData.message || 'Invalid response format from server');
      }
  
    } catch (error) {
      console.error('Audio mixing error:', error);
      setError({ 
        message: error.message || 'Failed to mix audio', 
        type: 'mixing' 
      });
      setIsMixedAudioLoaded(false);
      toast.error(`Audio mixing failed: ${error.message}`);
      
      if (lastMixedAudioUrlRef.current) {
        setMixedAudioUrl(lastMixedAudioUrlRef.current);
        toast.info('Restored previous mix');
      }
    } finally {
      setIsMixingAudio(false);
      isLoadingRef.current = false;
      isMixingAudioRef.current = false;
    }
  }, [
    selectedMusic,
    ttsKey,
    volume,
    filters,
    baseUrl,
    setNewMixedAudioUrl,
    validateAudioUrl,
    setError,
    setIsMixedAudioLoaded
  ]);
  
  // =============== AUDIO ERROR HANDLER =============== //
  const handleAudioError = useCallback((error) => {
    const errorMessages = {
      INVALID_URL: 'Invalid audio URL received',
      EXPIRED: 'Audio URL has expired, please regenerate',
      ACCESS_DENIED: 'Cannot access audio file',
      TIMEOUT: 'Audio loading timed out',
      PLAYBACK: 'Error during playback'
    };
  
    const message = errorMessages[error.type] || error.message || 'Audio error occurred';
    setError({ message, type: 'audio' });
  
    if (error.type === 'INVALID_URL' || error.type === 'EXPIRED') {
      setIsTTSLoaded(false);
      setIsSessionCreated(false);
    }
  
    toast.error(message);
  }, []);
  
  // =============== PUSH-TO-SPEAK EVENTS =============== //
  const handleRecordingToggle = useCallback((isRecordingNow) => {
    setIsRecording(isRecordingNow);
    setIsRecordingAnimation(isRecordingNow);
  }, []);
  
  // =============== VOLUME & FILTERS UI HANDLERS =============== //
  const handleVolumeChange = useCallback((type, newValue) => {
    console.log(`Volume change detected: ${type} => ${newValue}`);
    const value = Math.min(Math.max(Number(newValue), 0), 1);
    setVolume(prev => ({
        ...prev,
        [type]: value
    }));
    setHasChanges(true);
  }, [setVolume, setHasChanges]);
  
  const handlePresetSelect = useCallback((filterName, presetLabel) => {
    const preset = presetsMap[filterName]?.find(p => p.label === presetLabel);
    if (preset) {
      setPendingChanges(prev => ({
        ...prev,
        filters: {
          ...(prev.filters || {}),
          [filterName]: {
            ...prev.filters[filterName],
            ...preset,
            enabled: true,
            preset: presetLabel
          }
        }
      }));
      setHasChanges(true);
      logControl(`Selected preset ${presetLabel} for ${filterName}`, preset);
    }
  }, [setPendingChanges, setHasChanges, logControl]);
  
  const handleSoloModeChange = useCallback((enabled) => {
    const volumes = {
      bg_volume: enabled ? 0 : (previousVolumes?.bg ?? volume.bg_volume),
      tts_volume: enabled ? 0 : (previousVolumes?.tts ?? volume.tts_volume),
      overall_volume: volume.overall_volume
    };
    
    if (enabled && !previousVolumes) {
      setPreviousVolumes({
        bg: volume.bg_volume,
        tts: volume.tts_volume
      });
    } else if (!enabled) {
      setPreviousVolumes(null);
    }
    
    setVolume(volumes);
    setIsSoloMode(enabled);
    setHasChanges(true);
  }, [volume, previousVolumes, setVolume, setHasChanges]);

  

  useEffect(() => {
    const soloEnabled = filters.soundBowls?.enabled && filters.soundBowls?.solo_sound_bowls;
    if (soloEnabled !== isSoloMode) {
      handleSoloModeChange(soloEnabled);
    }
  }, [filters.soundBowls?.enabled, filters.soundBowls?.solo_sound_bowls, handleSoloModeChange]);
  
  // =============== AUDIO COMPLETION HANDLER (TRANSCRIPTION) =============== //
  const handleAudioComplete = useCallback(async (transcription) => {
    if (!transcription) {
      setError({ message: 'Transcription failed. Please try again.', type: 'transcription' });
      setIsTranscribing(false);
      toast.error('Transcription failed. Please try again.');
      return;
    }
  
    try {
      setCustomPrompt(transcription);
      setIsTranscribed(true);
      setIsTextEditorGlowing(true);
      setTimeout(() => setIsTextEditorGlowing(false), 2000);
      setIsTranscribing(false);
      await new Promise(resolve => setTimeout(resolve, 100));
      setIsScriptLoading(true);
      LoadingController.start();
      await handleGenerateScript(transcription);
    } catch (error) {
      setError({ message: error.message, type: 'script' });
      toast.error(`Error handling audio completion: ${error.message}`);
    }
  }, [handleGenerateScript]);
  
  // =============== CREATE SESSION (TTS SYNTHESIS) =============== //
  const handleCreateSession = useCallback(async () => {
    if (!meditationScript.trim()) {
      toast.error('Meditation script cannot be empty.');
      return;
    }
  
    try {
      setIsCreatingSession(true);
      setIsAudioLoading(true);
  
      const requestData = {
        text: meditationScript,
        voice: selectedVoice,
        speaking_rate: 1.0
      };
  
      console.log('[TTS-REQUEST] Request data:', {
        textLength: requestData.text.length,
        voice: requestData.voice,
        speakingRate: requestData.speaking_rate
      });
  
      const response = await fetch(`${baseUrl}/synthesize-speech`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Origin': 'https://pause.site'
        },
        credentials: 'include',
        mode: 'cors',
        body: JSON.stringify(requestData)
      });
  
      if (!response.ok) {
        let errorMessage;
        try {
          const errorData = await response.json();
          errorMessage = errorData.message || `HTTP error! status: ${response.status}`;
        } catch (e) {
          errorMessage = `HTTP error! status: ${response.status}`;
        }
        throw new Error(`Speech synthesis failed: ${errorMessage}`);
      }
  
      const responseData = await response.json();
      console.log('[TTS-RESPONSE] Full response:', responseData);
  
      if (responseData.status !== 'success' || !responseData.data) {
        console.error('[TTS-ERROR] Invalid response format:', responseData);
        throw new Error('Invalid response format from server');
      }
  
      const { file_path, filename } = responseData.data;
  
      if (!file_path || !filename) {
        console.error('[TTS-ERROR] Missing data:', { file_path, filename });
        throw new Error('Invalid response: Missing required data');
      }
  
      const validationResult = validateAudioUrl(file_path);
      console.log('[TTS-URL] Validation result:', validationResult);
  
      if (!validationResult.isValid) {
        console.error('[TTS-ERROR] URL validation failed:', validationResult);
        throw new Error('Invalid audio URL received');
      }
  
      setTTSKey(filename);
      setMixedAudioUrl(file_path);
      setIsTTSLoaded(true);
      setIsSessionCreated(true);
  
      console.log('[TTS-SUCCESS] Session created:', {
        filename,
        urlValid: true,
        isTTSLoaded: true,
        isSessionCreated: true
      });
  
      toast.success('Session created successfully!');
  
    } catch (err) {
      console.error('[TTS-ERROR] Session creation failed:', err);
      setError({ 
        message: err.message, 
        type: 'session' 
      });
      toast.error(`Session creation failed: ${err.message}`);
      setIsTTSLoaded(false);
      setIsSessionCreated(false);
    } finally {
      setIsCreatingSession(false);
      setIsAudioLoading(false);
    }
  }, [
    baseUrl,
    meditationScript,
    selectedVoice,
    validateAudioUrl,
    setError,
    setTTSKey,
    setMixedAudioUrl,
    setIsTTSLoaded,
    setIsSessionCreated
  ]);
  
  useEffect(() => {
    if (meditationScript !== '') {
      setIsScriptGenerated(true);
      console.log('Script updated - setting isScriptGenerated:', true);
    }
  }, [meditationScript]);
  
  useEffect(() => {
    isLoadingRef.current = isLoading;
  }, [isLoading]);
  
  useEffect(() => {
    isMixingAudioRef.current = isMixingAudio;
  }, [isMixingAudio]);
  
  // =============== AUDIO MIXING HANDLER (Manual Apply Changes) =============== //
  const handleManualApplyChanges = useCallback(async () => {
    if (!hasChanges) return;
    try {
      setIsMixingAudio(true);
      LoadingController.start();
  
      if (!ttsKey) {
        throw new Error('No audio session found. Please create a session first.');
      }
  
      if (!isSoloMode && !selectedMusic) {
        throw new Error('Please select background music first.');
      }
  
      const mixPayload = {
        bg_volume: isSoloMode ? 0 : Math.min(Math.max(Number(volume.bg_volume), 0), 1),
        tts_volume: isSoloMode ? 0 : Math.min(Math.max(Number(volume.tts_volume), 0), 1),
        overall_volume: Math.min(Math.max(Number(volume.overall_volume), 0), 1),
        bg_audio: encodeURIComponent(selectedMusic.startsWith('Background_Music/') ? 
          selectedMusic : `Background_Music/${selectedMusic}`),
        tts_audio: encodeURIComponent(ttsKey.startsWith('TTS/') ? 
          ttsKey : `TTS/${ttsKey}`),
        filters: filtersBackend
      };
  
      console.log('Sending mix payload:', mixPayload);
  
      const response = await fetch(`${baseUrl}/get-mixed-audio`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Origin': 'https://pause.site'
        },
        credentials: 'include',
        body: JSON.stringify(mixPayload)
      });
  
      if (!response.ok) {
        throw new Error(`Server error: ${response.status}`);
      }
  
      const responseData = await response.json();
      
      // FIXED: Changed the conditional check to correctly validate responseData.status
      if (responseData.status !== 'success' || !responseData.data?.file_path) {
        throw new Error('Invalid response format from server');
      }
  
      const validationResult = validateAudioUrl(responseData.data.file_path);
      if (!validationResult.isValid) {
        throw new Error('Invalid audio URL received');
      }
  
      lastMixedAudioUrlRef.current = responseData.data.file_path;
      setNewMixedAudioUrl(responseData.data.file_path);
      setHasChanges(false);
      setIsMixedAudioLoaded(true);
      toast.success('Changes applied successfully');
  
    } catch (error) {
      console.error('Audio mixing error:', error);
      setError({ message: error.message, type: 'mixing' });
      setIsMixedAudioLoaded(false);
      if (lastMixedAudioUrlRef.current) {
        setMixedAudioUrl(lastMixedAudioUrlRef.current);
        toast.info('Restored previous mix');
      }
    } finally {
      setIsMixingAudio(false);
      LoadingController.reset();
    }
  }, [
    hasChanges,
    ttsKey,
    selectedMusic,
    isSoloMode,
    volume,
    baseUrl,
    filtersBackend,
    validateAudioUrl,
    setNewMixedAudioUrl,
    setHasChanges,
    setIsMixedAudioLoaded
  ]);
  
  // =============== MUSIC SELECTION HANDLER =============== //
  const handleMusicSelection = useCallback(async (selectedFileName) => {
    if (isMixingAudioRef.current) {
      toast.info('Audio mixing is already in progress. Please wait.');
      return;
    }
    if (!selectedFileName) {
      return;
    }
    try {
      const cleanFileName = selectedFileName.replace(/^Background_Music\//, '');
      setSelectedMusic(cleanFileName);
      setHasChanges(true);
  
      const formattedFileName = `Background_Music/${cleanFileName}`;
  
      const mixPayload = {
        bg_volume: Math.min(Math.max(Number(volume.bg_volume), 0), 1),
        tts_volume: Math.min(Math.max(Number(volume.tts_volume), 0), 1),
        overall_volume: Math.min(Math.max(Number(volume.overall_volume), 0), 1),
        bg_audio: encodeURIComponent(formattedFileName),
        tts_audio: encodeURIComponent(ttsKey.startsWith('TTS/') ? ttsKey : `TTS/${ttsKey}`),
        filters: filtersBackend
      };
  
      console.log('Initial mix payload:', mixPayload);
      await handleAudioMixing();
      
    } catch (error) {
      console.error('Error in music selection:', error);
      toast.error('Failed to process music selection');
      setSelectedMusic(selectedMusic);
    }
  }, [
    isMixingAudioRef,
    selectedMusic,
    hasChanges,
    handleAudioMixing,
    volume,
    ttsKey,
    filtersBackend
  ]);
  
  const handleTextEditorChange = useCallback((e) => {
    const newValue = e.target.value;
    if (editorMode === 'prompt') {
      setCustomPrompt(newValue);
    } else {
      setMeditationScript(newValue);
      if (isScriptGenerated) {
        setIsScriptGenerated(false);
      }
    }
  }, [editorMode, isScriptGenerated]);
  
  const handlePromptKeyPress = useCallback((event) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      handleGenerateScript();
    }
  }, [handleGenerateScript]);
  
  const handleScriptKeyPress = useCallback((event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleCreateSession();
    }
  }, [handleCreateSession]);
  
  const handleAudioLoaded = useCallback(() => {
    setIsMixedAudioLoaded(true);
    toast.success('Audio loaded successfully!');
  }, []);
  
  const resetForNewMeditation = useCallback(() => {
    setCustomPrompt('');
    setMeditationScript('');
    setSelectedOptions([]);
    setIsScriptGenerated(false);
    setIsSessionCreated(false);
    setIsPersonalizationMode(false);
    setError({ message: null, type: null });
    setIsTextEditorGlowing(false);
    setIsEnhancedEditorGlowing(false);
    setHasChanges(false);
    setIsAudioLoading(false);
    setIsTTSLoaded(false);
    setIsCreatingSession(false);
    setPendingChanges({ volume: null, filters: {} });
  }, []);
  
  const handlePlayingStateChange = useCallback((isPlayingNow) => {
    setIsPlaying(isPlayingNow);
  }, []);
  
  useEffect(() => {
    const handleFetchMusic = async () => {
      try {
        setIsMusicLoading(true);
        const response = await fetch(`${baseUrl}/load-music`, {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' },
        });
  
        if (!response.ok) {
          console.warn('Failed to fetch music files:', response.statusText);
          return;
        }
  
        const responseData = await response.json();
        console.log('Music data response:', responseData);
  
        if (responseData.status === 'success' && responseData.data && Array.isArray(responseData.data.musicFiles)) {
          const musicData = responseData.data.musicFiles;
          setMusicFiles(musicData.map(file => ({
            name: file.name.replace(/^Background_Music\/?/i, '').trim(),
            displayName: file.name.replace(/^Background_Music\/?/i, '').replace('.mp3', '').trim()
          })));
          console.log('Music library loaded successfully');
        } else {
          console.warn('Unexpected music data format:', responseData);
          setError({
            message: 'Unexpected music data format received from the server.',
            type: 'music'
          });
        }
      } catch (error) {
        console.warn('Error loading music library:', error);
        setError({
          message: 'Failed to load music library. This might affect background music selection.',
          type: 'music'
        });
      } finally {
        setIsMusicLoading(false);
      }
    };
  
    handleFetchMusic();
  }, [baseUrl]);
  
  return (
    <ConversationProvider>
      <div className="App">
        <div className="header-container">
          <div className="header">
            <img src={logo} alt="App Logo" className="app-logo" />
          </div>
        </div>
  
        {console.log('CreateButton props:', { isCreatingSession, isScriptGenerated, isTTSLoaded, isSessionCreated })}
  
        <div className={`meditation-content 
          ${showDecisionTree ? 'decision-tree-open' : ''} 
          ${sidebarState.isOpen ? 'sidebar-open' : ''} 
          ${sidebarState.hasBackdrop ? 'backdrop-active' : ''}`}>
  
          {!isPersonalizationMode && (
            <div className="tree-control-container">
              <div
                className="wave-btn-container"
                onTouchStart={(e) => {
                  e.currentTarget?.classList.add('touched');
                  setTimeout(() => e.currentTarget?.classList.remove('touched'), 3000);
                }}
              >
                <div className="wave-btn-tooltip">
                  Create a guided meditation session with step-by-step options and prompts
                </div>
                <button
                  className="wave-btn"
                  onClick={() => {
                    resetForNewMeditation();
                    setShowDecisionTree(prev => !prev);
                  }}
                  aria-label="Open meditation guide"
                >
                  <span className="wave-effect" />
                  <span className="wave-text">Guide</span>
                </button>
              </div>
  
              {showDecisionTree && (
                <div className="decision-tree-wrapper">
                  <DecisionTree
                    onScriptGenerated={handleGenerateScript}
                    customPrompt={customPrompt}
                    setCustomPrompt={setCustomPrompt}
                    setMeditationScript={setMeditationScript}
                    isScriptLoading={isScriptLoading}
                    setIsScriptLoading={setIsScriptLoading}
                    PushToSpeakButton={PushToSpeakButton}
                    isPersonalizationMode={isPersonalizationMode}
                    setIsPersonalizationMode={setIsPersonalizationMode}
                    selectedOptions={selectedOptions}
                    setSelectedOptions={setSelectedOptions}
                    setShowDecisionTree={setShowDecisionTree}
                    isScriptGenerated={isScriptGenerated}
                    personalizedNote={personalizedNote}
                    setPersonalizedNote={setPersonalizedNote}
                    selectedVoice={selectedVoice}
                    setIsScriptGenerated={setIsScriptGenerated}
                    setEditorMode={setEditorMode}
                    setError={setError}
                    cleanText={cleanText}
                    LoadingController={LoadingController}
                  />
                </div>
              )}
            </div>
          )}
  
          <Preloader isLoading={isTranscribing} action="transcribing" />
          <Preloader isLoading={isScriptLoading} action="generating-script" />
          <Preloader isLoading={isCreatingSession} action="creating-session" />
          <Preloader isLoading={isMixingAudio} action="mixing-audio" />
  
          <div className="main-controls">
            <PushToSpeakButton
              onRecordingComplete={handleAudioComplete}
              isRecording={isRecording}
              onRecordingToggle={handleRecordingToggle}
              isTranscribing={isTranscribing}
              setIsTranscribing={setIsTranscribing}
              setIsRecordingAnimation={setIsRecordingAnimation}
              disabled={isLoading}
              isPersonalizationMode={isPersonalizationMode}
              resetHasGeneratedScript={resetForNewMeditation}
            />
          </div>
  
          <div className="voice-selector-container">
            <VoiceSelector 
              selectedVoice={selectedVoice} 
              onVoiceChange={setSelectedVoice} 
            />
          </div>
  
          <MergedTextEditor
            mode={editorMode}
            placeholder={
              editorMode === 'prompt'
                ? isPersonalizationMode
                  ? 'Record or type to personalize your meditation:'
                  : 'Enter a custom prompt...'
                : 'Meditation script goes here...'
            }
            value={editorMode === 'prompt' ? customPrompt : meditationScript}
            onChange={handleTextEditorChange}
            onKeyDown={editorMode === 'prompt' ? handlePromptKeyPress : handleScriptKeyPress}
            isTranscribed={isTranscribed}
            isScriptGenerated={isScriptGenerated}
            onGenerateScript={() => handleGenerateScript(customPrompt)}
            isScriptLoading={isScriptLoading}
            isPersonalizationMode={isPersonalizationMode}
            isGlowing={editorMode === 'prompt' ? isTextEditorGlowing : isEnhancedEditorGlowing}
            setIsGlowing={editorMode === 'prompt' ? setIsTextEditorGlowing : setIsEnhancedEditorGlowing}
            isSessionCreated={isSessionCreated}
          />
  
          <CreateButton
            isCreatingSession={isCreatingSession}
            isScriptGenerated={isScriptGenerated}
            isTTSLoaded={isTTSLoaded}
            isSessionCreated={isSessionCreated}
            onClick={handleCreateSession}
          />
  
          {error?.message && (
            <div 
              className={`error-message ${error.type ? `error-${error.type}` : ''}`}
              role="alert"
              aria-live="polite"
            >
              <div className="error-content">
                {error.type === 'audio' && <span className="error-icon">🔊</span>}
                {error.type === 'mixing' && <span className="error-icon">🔄</span>}
                {error.type === 'script' && <span className="error-icon">📝</span>}
                {error.type === 'session' && <span className="error-icon">⚠️</span>}
                {error.type === 'music' && <span className="error-icon">🎵</span>}
                {error.type === 'transcription' && <span className="error-icon">🗣️</span>}
                <span className="error-text">{error.message}</span>
              </div>
              <button 
                className="error-dismiss"
                onClick={() => setError({ message: null, type: null })}
                aria-label="Dismiss error"
                type="button"
              >
                ✕
              </button>
            </div>
          )}
  
          {isTTSLoaded && (
            <div className={`audio-controls-container ${sidebarState.isOpen ? 'sidebar-open' : ''}`}>
              <AudioPlayer
                initialAudioUrl={mixedAudioUrl}
                volume={memoizedVolume}
                onError={handleAudioError}
                onPlayingStateChange={handlePlayingStateChange}
                onAudioLoaded={handleAudioLoaded}
                onMusicSelect={handleMusicSelection}
                musicLibrary={memoizedMusicLibrary}
                isSessionCreated={isSessionCreated}
                isMixingAudio={isMixingAudio}
                isMusicLoading={isMusicLoading}
                selectedMusic={selectedMusic}
                isTTSLoaded={isTTSLoaded}
              />
  
              <VolumeControls
                volume={volume}
                baseUrl={baseUrl}
                setVolume={handleVolumeChange}
                filters={filters}
                onApplyFilters={handleManualApplyChanges}
                selectedMusic={selectedMusic}
                ttsKey={ttsKey}
                isMixingAudio={isMixingAudio}
                disabled={isMixingAudio}
                isMusicLoading={isMusicLoading}
                hasChanges={hasChanges}
                setHasChanges={setHasChanges}
                pendingVolume={pendingChanges.volume}
                pendingFilters={pendingChanges.filters}
                setPendingChanges={setPendingChanges}
                onPresetSelect={handlePresetSelect}
                isSessionCreated={isSessionCreated}
                onFilterChange={(filterName, updatedFilter) => {
                  setPendingChanges(prev => ({
                    ...prev,
                    filters: {
                      ...(prev.filters || {}),
                      [filterName]: {
                        ...filters[filterName],
                        ...updatedFilter,
                        enabled: updatedFilter.enabled ?? filters[filterName]?.enabled ?? true
                      }
                    }
                  }));
                  setHasChanges(true);
                }}
              />
  
              {mixedAudioUrl && !isMixingAudio && (
                <ExportButton text="Export Audio" mixedAudioUrl={mixedAudioUrl} />
              )}
            </div>
          )}
  
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="dark"
            transition={Slide}
            closeButton={({ closeToast }) => (
              <button
                className="Toastify__close-button"
                onClick={closeToast}
                aria-label="Close notification"
                type="button"
              >
                <svg
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M13 1L1 13M1 1L13 13"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                  />
                </svg>
              </button>
            )}
          />
        </div>
      </div>
    </ConversationProvider>
  );
};

export default MeditationApp;
