export const presetsMap = {
    // StereoPan and Reverb sections remain unchanged as they are correct
    stereoPan: [
        {
            label: "Gentle Space",
            value: 0.3,
            speed: 15,
            pattern: 'sine',
            hold_time: 2.0,
            description: "Subtle spatial enhancement for immersion"
        },
        {
            label: "Dreamy Motion",
            value: 0.5,
            speed: 8,
            pattern: 'triangle',
            hold_time: 3.0,
            description: "Slow, dream-like spatial movement"
        },
        {
            label: "Organic Flow",
            value: 0.4,
            speed: 12,
            pattern: 'random',
            hold_time: 2.0,
            description: "Natural, unpredictable spatial movement"
        }
    ],

    reverb: [
        {
            label: "Sacred Hall",
            impulse_response_path: "s3://pausebeta20-meditation-assets/IRs/M7/HALL_B1.wav",
            wet: 1.8,
            dry: 0.7,
            predelay: 20,
            description: "Large, ethereal space perfect for deep meditation"
        },
        {
            label: "Chamber",
            impulse_response_path: "s3://pausebeta20-meditation-assets/IRs/M7/CHAMBER_A1.wav",
            wet: 1.4,
            dry: 0.8,
            predelay: 15,
            description: "Intimate space with warm, natural reflections"
        },
        {
            label: "Concert Hall",
            impulse_response_path: "s3://pausebeta20-meditation-assets/IRs/M7/CONHALL_A1.wav",
            wet: 1.2,
            dry: 0.85,
            predelay: 12,
            description: "Clear, balanced space with smooth decay"
        },
        {
            label: "Plate",
            impulse_response_path: "s3://pausebeta20-meditation-assets/IRs/M7/PLATE_A1.wav",
            wet: 1.6,
            dry: 0.75,
            predelay: 16,
            description: "Smooth, dense reverb perfect for vocals"
        },
        {
            label: "Cathedral",
            impulse_response_path: "s3://pausebeta20-meditation-assets/IRs/M7/CATHDRL_A1.wav",
            wet: 1.3,
            dry: 0.82,
            predelay: 25,
            description: "Expansive, spiritual space with crystalline reflections"
        }
    ],

    // Updated soundBowls section with new optimized presets
    soundBowls: [
        {
            label: "Root Chakra Bowl",
            base_frequency: 194.18,  // G3 - traditional root chakra frequency
            bowl_size: "large",
            rub_speed: 1.8,         // Slower, grounding movement
            rub_pressure: 0.82,
            resonance: 0.99997,
            volume: 0.85,
            impulse_response_path: "s3://pausebeta20-meditation-assets/IRs/M7/CATHDRL_A1.wav",
            wet: 1.4,
            dry: 0.8,
            description: "Deep, grounding resonance with rich overtones - perfect for deep meditation"
        },
        {
            label: "Heart Chakra Bowl",
            base_frequency: 341.3,   // F4 - heart chakra frequency
            bowl_size: "medium",
            rub_speed: 2.2,
            rub_pressure: 0.76,
            resonance: 0.99995,
            volume: 0.82,
            impulse_response_path: "s3://pausebeta20-meditation-assets/IRs/M7/CHAMBER_A1.wav",
            wet: 1.2,
            dry: 0.85,
            description: "Warm, balanced tone centered on heart frequency harmonics"
        },
        {
            label: "Crown Crystal Bowl",
            base_frequency: 432.0,   // A4 - harmonic alignment frequency
            bowl_size: "small",
            rub_speed: 2.5,
            rub_pressure: 0.72,
            resonance: 0.99993,
            volume: 0.80,
            impulse_response_path: "s3://pausebeta20-meditation-assets/IRs/M7/HALL_B1.wav",
            wet: 1.3,
            dry: 0.82,
            description: "Clear, bright tones with crystalline harmonics"
        },
        {
            label: "Tibetan Healing Bowl",
            base_frequency: 285.0,   // D4 - traditional healing frequency
            bowl_size: "medium",
            rub_speed: 2.0,
            rub_pressure: 0.78,
            resonance: 0.99995,
            volume: 0.83,
            impulse_response_path: "s3://pausebeta20-meditation-assets/IRs/M7/CONHALL_A1.wav",
            wet: 1.25,
            dry: 0.84,
            description: "Traditional Tibetan bowl frequencies with rich harmonic content"
        },
        {
            label: "Deep Earth Bowl",
            base_frequency: 126.22,  // B2 - earth frequency
            bowl_size: "large",
            rub_speed: 1.6,
            rub_pressure: 0.85,
            resonance: 0.99997,
            volume: 0.87,
            impulse_response_path: "s3://pausebeta20-meditation-assets/IRs/M7/CATHDRL_A1.wav",
            wet: 1.5,
            dry: 0.78,
            description: "Ultra-deep resonance with powerful grounding effect"
        }
    ],

    // Ocean Waves presets remain unchanged
    oceanWaves: [
        {
            label: "Ocean Meditation",
            wave_frequency: 0.08,
            noise_amplitude: 0.15,
            volume: 0.75,
            low_cut: 40,
            high_cut: 6000,
            description: "Gentle waves synchronized with breath"
        },
        {
            label: "Coastal Peace",
            wave_frequency: 0.12,
            noise_amplitude: 0.2,
            volume: 0.72,
            low_cut: 50,
            high_cut: 5500,
            description: "Balanced coastal atmosphere"
        },
        {
            label: "Deep Ocean",
            wave_frequency: 0.06,
            noise_amplitude: 0.25,
            volume: 0.78,
            low_cut: 30,
            high_cut: 4000,
            description: "Deep, resonant oceanic presence"
        }
    ]
};

// Updated effect controls aligned with new presets
export const effectControls = {
    // Other controls remain unchanged
    stereoPan: {
        value: { min: 0, max: 1, default: 0.3, step: 0.01 },
        speed: { min: 4, max: 30, default: 15, step: 0.5 },
        hold_time: { min: 0.5, max: 5.0, default: 2.0, step: 0.1 },
        pattern: {
            options: ['sine', 'triangle', 'square', 'random', 'hold_center'],
            default: 'sine',
        }
    },

    // Updated soundBowls controls
    soundBowls: {
        base_frequency: {
            min: 126.0,    // B2
            max: 648.0,    // E5
            default: 285.0, // D4
            step: 0.1,
            label: "Bowl Frequency (Hz)",
            tooltip: "Fundamental frequency of the singing bowl"
        },
        rub_speed: {
            min: 1.5,
            max: 3.0,
            default: 2.2,
            step: 0.1,
            label: "Mallet Speed",
            tooltip: "Speed of the continuous rubbing motion"
        },
        rub_pressure: {
            min: 0.65,
            max: 0.85,
            default: 0.76,
            step: 0.01,
            label: "Contact Pressure",
            tooltip: "Pressure of mallet against bowl rim"
        },
        volume: {
            min: 0.5,
            max: 0.9,
            default: 0.82,
            step: 0.01,
            label: "Bowl Volume",
            tooltip: "Overall volume of the singing bowl"
        },
        bowl_size: {
            options: [
                { value: 'small', label: 'Small Crystal Bowl' },
                { value: 'medium', label: 'Medium Tibetan Bowl' },
                { value: 'large', label: 'Large Temple Bowl' }
            ],
            default: 'medium',
            label: "Bowl Type",
            tooltip: "Affects resonance character and harmonics"
        },
        wet: {
            min: 0.8,
            max: 2.0,
            default: 1.3,
            step: 0.1,
            label: "Space Amount",
            tooltip: "Amount of simulated space/reverb"
        },
        dry: {
            min: 0.6,
            max: 1.0,
            default: 0.82,
            step: 0.02,
            label: "Direct Sound",
            tooltip: "Amount of direct bowl sound"
        },
        resonance: {
            min: 0.9991,
            max: 0.99997,
            default: 0.99995,
            step: 0.00001,
            label: "Resonance",
            tooltip: "Bowl resonance/sustain"
        }
    },

    // Other controls sections remain unchanged
    oceanWaves: { /* ... */ },
    reverb: { /* ... */ }
};

// Frequency mappings remain unchanged
export const frequencyMappings = {
    rootChakra: 194.18,   // G3
    sacralChakra: 210.42, // G#3
    solarPlexus: 126.22,  // B2
    heartChakra: 341.3,   // F4
    throatChakra: 384.0,  // G4
    thirdEye: 417.0,      // G#4
    crownChakra: 432.0,   // A4
    earthTone: 126.22,    // B2
    om: 432.0,            // A4
    healing: 285.0,       // D4
    harmony: 396.0        // G4
};