import React, { useRef, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';

const MergedTextEditor = ({
  mode,
  placeholder,
  value,
  onChange,
  onKeyDown,
  isTranscribed,
  isScriptGenerated,
  onGenerateScript,
  isScriptLoading,
  isPersonalizationMode,
  isGlowing,
  setIsGlowing,
  isSessionCreated,
}) => {
  const textAreaRef = useRef(null);
  const containerRef = useRef(null);
  const userScrolling = useRef(false);
  const lastScrollPosition = useRef(0);

  // When the user scrolls manually, mark that so auto-scroll won't override immediately
  const handleScroll = useCallback(() => {
    const textarea = textAreaRef.current;
    if (!textarea) return;

    const isNearBottom = textarea.scrollHeight - textarea.scrollTop <= textarea.clientHeight + 100;
    
    if (Math.abs(textarea.scrollTop - lastScrollPosition.current) > 50) {
      userScrolling.current = true;
      setTimeout(() => {
        userScrolling.current = false;
      }, 150);
    }
    lastScrollPosition.current = textarea.scrollTop;
  }, []);

  // Auto-scroll effect: if in script mode and user is not scrolling, scroll to bottom
  useEffect(() => {
    if (mode === 'script' && textAreaRef.current && value) {
      const textarea = textAreaRef.current;
      if (!userScrolling.current) {
        const isNearBottom = textarea.scrollHeight - textarea.scrollTop <= textarea.clientHeight + 100;
        if (isNearBottom) {
          requestAnimationFrame(() => {
            textarea.scrollTop = textarea.scrollHeight;
          });
        }
      }
    }
  }, [value, mode]);

  // Animation for new text
  useEffect(() => {
    if (mode === 'script' && value && textAreaRef.current) {
      const textarea = textAreaRef.current;
      textarea.style.transition = 'none';
      textarea.style.opacity = '0.8';
      
      requestAnimationFrame(() => {
        textarea.style.transition = 'opacity 0.3s ease';
        textarea.style.opacity = '1';
      });
    }
  }, [value, mode]);

  // Focus and glow effect
  useEffect(() => {
    if (containerRef.current) {
      if (isGlowing) {
        textAreaRef.current?.focus();
        containerRef.current.classList.add('glow-effect');
      } else {
        containerRef.current.classList.remove('glow-effect');
      }
    }
  }, [isGlowing]);

  // Script generation animation
  useEffect(() => {
    if (isScriptGenerated && textAreaRef.current && containerRef.current) {
      textAreaRef.current.classList.add('fade-in');
      containerRef.current.classList.add('script-generated-glow');
      
      const fadeTimeout = setTimeout(() => {
        textAreaRef.current?.classList.remove('fade-in');
      }, 500);
      
      const glowTimeout = setTimeout(() => {
        containerRef.current?.classList.remove('script-generated-glow');
      }, 1500);
      
      return () => {
        clearTimeout(fadeTimeout);
        clearTimeout(glowTimeout);
      };
    }
  }, [isScriptGenerated]);

  // Local handler for text changes
  const handleTextChange = useCallback((e) => {
    onChange(e);
  }, [onChange]);

  // Local keydown handler with Safari space-key fix
  const handleLocalKeyDown = useCallback(
    (e) => {
      if (e.key === ' ' && e.target.selectionStart === e.target.selectionEnd) {
        e.preventDefault();
        const pos = e.target.selectionStart;
        const newValue = e.target.value.slice(0, pos) + ' ' + e.target.value.slice(pos);
        onChange({ target: { value: newValue } });
        
        requestAnimationFrame(() => {
          if (textAreaRef.current) {
            textAreaRef.current.selectionStart = pos + 1;
            textAreaRef.current.selectionEnd = pos + 1;
          }
        });
      }
      onKeyDown?.(e);
    },
    [onChange, onKeyDown]
  );

  return (
    <div
      ref={containerRef}
      className={`text-container ${isGlowing ? 'glow-effect' : ''} 
                 ${isScriptGenerated ? 'script-generated-glow' : ''} 
                 ${isSessionCreated ? 'session-created' : ''} ${mode}`}
    >
      <textarea
        ref={textAreaRef}
        className={`text-area ${mode === 'script' ? 'streaming-text' : ''}`}
        placeholder={placeholder}
        value={value}
        onChange={handleTextChange}
        onKeyDown={handleLocalKeyDown}
        onScroll={handleScroll}
        spellCheck="true"
        autoCapitalize="sentences"
        readOnly={isScriptGenerated && !isPersonalizationMode}
        style={{ scrollBehavior: 'smooth' }}
      />
      {mode === 'prompt' && isPersonalizationMode && !isScriptGenerated && (
        <div className="generate-script-btn-container">
          <button
            className="wave-btn"
            onClick={onGenerateScript}
            disabled={isScriptLoading}
            aria-label="Generate Script"
          >
            <span className="wave-effect" />
            <span className="wave-text">Generate Script</span>
          </button>
        </div>
      )}
    </div>
  );
};

MergedTextEditor.propTypes = {
  mode: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onKeyDown: PropTypes.func,
  isTranscribed: PropTypes.bool,
  isScriptGenerated: PropTypes.bool.isRequired,
  onGenerateScript: PropTypes.func.isRequired,
  isScriptLoading: PropTypes.bool.isRequired,
  isPersonalizationMode: PropTypes.bool.isRequired,
  isGlowing: PropTypes.bool.isRequired,
  setIsGlowing: PropTypes.func.isRequired,
  isSessionCreated: PropTypes.bool.isRequired,
};

MergedTextEditor.defaultProps = {
  placeholder: '',
  onKeyDown: null,
  isTranscribed: false,
};

export default MergedTextEditor;